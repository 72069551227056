<script>
// Data section splitters (with labels)

// import Overlay from '../../mixins/overlay.js'
import Overlay from "../../../../node_modules/trading-vue-js/src/mixins/overlay.js";

export default {
  name: "PriceAverage",
  mixins: [Overlay],

  // Define internal setting & constants here
  computed: {
    sett() {
      return this.$props.settings;
    },
    new_font() {
      return this.sett.font || "12px " + this.$props.font.split("px").pop();
    },
    last_bar() {
      if (this.$props.layout.candles.length === 0) return 0;
      return this.$props.layout.candles[this.$props.layout.candles.length - 1]
        .raw;
    },
    flag_color() {
      return this.sett.flagColor || "#4285f4";
    },
    label_color() {
      return this.sett.labelColor || "#fff";
    },
    line_color() {
      return this.sett.lineColor || "#4285f4";
    },
    line_width() {
      return this.sett.lineWidth || 1.0;
    },
    x_position() {
      return this.sett.xPosition || 0.9;
    },
    getMarkPrice() {
      var price =
        this.$store.getters["trading/binance/futures/stream/RECENT_TRADE"];

      if (price) {
        return price;
      } else {
        return 0;
      }
    },
    positionInformation() {
      var data =
        this.$store.getters[
          "trading/binance/futures/user/CURRENT_POSITION_INFORMATION"
        ];
      return data;
    },
  },
  methods: {
    roundRect(ctx, x, y, width, height, radius, color, fill, stroke) {
      ctx.beginPath();
      ctx.moveTo(x + radius.tl, y);
      ctx.lineTo(x + width - radius.tr, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
      ctx.lineTo(x + width, y + height - radius.br);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius.br,
        y + height,
      );
      ctx.lineTo(x + radius.bl, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
      ctx.lineTo(x, y + radius.tl);
      ctx.quadraticCurveTo(x, y, x + radius.tl, y);
      ctx.closePath();
      if (fill) {
        ctx.fill();
        ctx.fillStyle = color;
      }
      if (stroke) {
        ctx.setLineDash([0, 0]);
        ctx.stroke();
      }
    },
    meta_info() {
      return { author: "ImPurple", version: "1.0.1" };
    },
    draw(ctx) {
      const layout = this.$props.layout;
      ctx.lineWidth = this.line_width;
      this.positionInformation.forEach((item) => {
        var p = [
          0,
          parseFloat(parseFloat(item.entryPrice).toFixed(3)),
          parseFloat(parseFloat(item.positionAmt).toFixed(3)),
          parseFloat(parseFloat(item.leverage).toFixed(3)),
        ];
        // console.log(data)
        // var p = [0, this.positionInformation[0].entryPrice, this.positionInformation[0].positionAmt]

        ctx.strokeStyle = p[2] > 0 ? "#5cc585" : "#e35561";
        ctx.beginPath();
        p[0] = this.last_bar[0];
        // p[0] = current_time
        // console.log(last)
        // const x = layout.t2screen(currentTime) // x - Mapping
        const y = layout.$2screen(p[1]); // y - Mapping
        ctx.setLineDash([5, 5]);
        ctx.moveTo(0, y);
        ctx.lineTo(this.layout.width, y);
        ctx.stroke();
        if (p[1]) this.draw_PNL(ctx, y, p);
        if (p[2]) this.draw_Amt(ctx, y, p);
      });
    },

    // calc () {
    //   return {
    //     update: 'console.log(high, low)'
    //   }
    // },

    draw_PNL(ctx, y, p) {
      ctx.font = this.new_font;

      const pos = this.x_position;
      // eslint-disable-next-line no-unused-vars
      var offset = 20;
      var PNL = ((this.getMarkPrice.price - p[1]) * p[2]).toFixed(2);
      const w = ctx.measureText(p[1] * p[2]).width + offset;

      let x = this.layout.width * (1.0 - pos);

      x = Math.floor(x);
      var color = p[2] > 0 ? "#ffffff" : "#ffffff";
      ctx.fillStyle = color;

      // eslint-disable-next-line no-unused-vars

      ctx.beginPath();
      this.roundRect(
        ctx,
        x,
        y - 10,
        w,
        20,
        {
          tl: 4,
          br: 0,
          tr: 0,
          bl: 4,
        },
        color,
        true,
        true,
      );

      ctx.closePath();
      ctx.fill();
      // ctx.fillStyle = PNL > 0 ? '#5cc585' : 'e35561'
      ctx.fillStyle = "#000";
      // ctx.textAlign = 'left'
      var PNLText = PNL > 0 ? "+ " + PNL : PNL;

      // (parseFloat((this.getMarkPrice - this.positionInformation[0].entryPrice) * this.positionInformation[0].positionAmt).toFixed(3)).toString() + ' ', x + 15 * -1, y + 4)
      ctx.fillText("PNL " + PNLText, x + 4, y + 4);
    },

    draw_Amt(ctx, y, p) {
      ctx.font = this.new_font;

      const pos = this.x_position;
      var offset = 10;
      // eslint-disable-next-line no-unused-vars
      var PNLOffset = ctx.measureText(p[1] * p[2]).width + 20;
      const w = ctx.measureText(parseFloat(p[2]).toFixed(3)).width + offset;
      let x = this.layout.width * (1.0 - pos);

      x = Math.floor(x);
      var color = p[2] > 0 ? "#5cc585" : "#e35561";
      ctx.fillStyle = color;

      // eslint-disable-next-line no-unused-vars

      ctx.beginPath();
      this.roundRect(
        ctx,
        x + PNLOffset,
        y - 10,
        w,
        20,
        {
          tl: 0,
          br: 4,
          tr: 4,
          bl: 0,
        },
        color,
        true,
        true,
      );

      ctx.closePath();
      ctx.fill();
      ctx.fillStyle = "#fff";
      // ctx.textAlign = 'left'

      // (parseFloat((this.getMarkPrice - this.positionInformation[0].entryPrice) * this.positionInformation[0].positionAmt).toFixed(3)).toString() + ' ', x + 15 * -1, y + 4)
      ctx.fillText(p[2].toFixed(3), PNLOffset + x + 4, y + 4);
    },
    use_for() {
      return ["PriceAverage"];
    },
  },
  data() {
    return {};
  },
};
</script>
