<template>
  <div></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import binance from "@/APIs/binanceAPI";
export default {
  beforeDestroy() {
    this.wss.forEach((ws) => {
      ws.close();
    });
  },
  mounted() {
    this.initialize();
  },
  data() {
    return {
      wss: [],
    };
  },
  methods: {
    async initialize() {
      this.$store.dispatch(
        "trading/preferance/system/api/SET_API_LOADED",
        false,
      );
      await this.StartUserDataStream();
      await this.GetFuturesPositionInformation();
      await this.GetFuturesAccount();
      await this.GetFuturesAllOpenOrders();
      this.$store.dispatch(
        "trading/preferance/system/api/SET_API_LOADED",
        true,
      );
    },
    async StartUserDataStream() {
      binance.futuresGetDataStream().then((r) => {
        if (r.listenKey) {
          console.log("USER ACTIVE");
          this.$store.dispatch(
            "trading/binance/futures/user/SET_LISTEN_KEY",
            r.listenKey,
          );
          var ws = binance.futuresUserDataStream(r.listenKey, (e) => {
            if (e.e === "ORDER_TRADE_UPDATE") {
              console.log(e);
              if (e.o.X === "FILLED") {
                this.$toasted.global.notice("Order Filled");
                this.$store.dispatch(
                  "trading/binance/futures/user/DELETE_OPEN_ORDER",
                  e.o.i,
                );
                this.GetFuturesPositionInformation();
              } else if (e.o.X === "EXPIRED") {
                console.log("Expried");
              }
            } else if (e.e === "ACCOUNT_UPDATE") {
              console.log(e);
              this.$store.dispatch(
                "trading/binance/futures/user/CHANGE_WALLET_BALANCE",
                e.a.B[0].cw,
              );
            }
          });
          this.wss.push(ws);
        }
        //
      });
    },
    async GetFuturesAllOpenOrders() {
      binance.futuresGetAllOpenOrders((r) => {
        this.$store.dispatch("trading/binance/futures/user/SET_OPEN_ORDERS", r);
      });
    },
    async GetFuturesPositionInformation() {
      const data = await binance.futuresPositionInformation({
        symbol: "BTCUSDT",
      });
      this.$store.dispatch(
        "trading/binance/futures/user/SET_POSITION_INFORMATION",
        data,
      );
    },
    async GetFuturesAccount() {
      binance.futuresGetAccount((r) => {
        this.$store.dispatch(
          "trading/binance/futures/user/SET_ACCOUNT_INFORMATION",
          r,
        );
      });
    },
  },
};
</script>

<style></style>
