<template>
  <v-container class="surface" fluid fill-height style="height: 100%">
    <trading-navigation-bar />

    <stream-receiver />

    <user-receiver
      v-if="$store.state.trading.binance.futures.user.data.APIConnection"
    />

    <!-- v-if="$store.state.trading.preferance.system.api.data.APILoaded" -->

    <v-row class="pa-0 surface">
      <v-col cols="12" class="pa-0 mt-1">
        <v-card
          width="100%"
          height="100%"
          color="background"
          class="overflow-hidden rounded-0'"
          outlined
        >
          <ticker-panel />
        </v-card>
      </v-col>
      <v-col cols="12" class="pa-0">
        <grid-layout
          ref="gridLayout"
          :layout.sync="layout"
          :layouts="layouts"
          :responsive-layouts="layouts"
          :margin="[$vuetify.breakpoint.xs ? 0 : 4, 4]"
          :col-num="24"
          :row-height="30"
          :is-draggable="$vuetify.breakpoint.smAndUp"
          :is-resizable="$vuetify.breakpoint.smAndUp"
          :responsive="responsive"
          :vertical-compact="true"
          :use-css-transforms="true"
          @breakpoint-changed="breakpointChangedEvent"
        >
          <grid-item
            v-for="item in layout"
            :key="item.name"
            :static="item.static"
            :isResizable="$vuetify.breakpoint.smAndUp ? item.resizable : false"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :style="
              $vuetify.breakpoint.smAndUp
                ? 'touch-action: none;'
                : 'touch-action: pan-y;'
            "
            drag-allow-from=".vue-draggable-handle"
            :drag-ignore-from="$vuetify.breakpoint.smAndUp ? '.no-drag' : ''"
          >
            <resize-observer :name="item.name" @notify="handleResize" />
            <v-card
              width="100%"
              height="100%"
              color="background"
              class="overflow-hidden"
              flat
              :class="$vuetify.breakpoint.xs ? 'rounded-0' : 'rounded-lg'"
            >
              <component :ref="item.name" :is="item.component" />
            </v-card>
          </grid-item>
        </grid-layout>
        <order-form-bottom-bar v-if="$vuetify.breakpoint.smAndDown" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TradingNavigationBar from "@/components/Trading/NavigationBar/TradingNavigationBar.vue";
/* eslint-disable vue/no-unused-components */
import {
  ResizeObserver,
  ChartPanel,
  EmptyPanel,
  TickerPanel,
  TradesPanel,
  OrderBookPanel,
  TestPanel,
  InformationPanel,
  OrderFormPanel,
  OrderFormBottomBar,
} from "@/components/Trading/Panels";

import { GridLayout, GridItem } from "vue-grid-layout";
import { layoutGrid } from "@/data/trading/systemPreferance/layout";
import StreamReceiver from "@/components/utils/binance/StreamReceiver.vue";
import setMeta from "@/utils/setMeta";
import APIKeyConnectMenu from "@/components/Trading/Card/APIKeyConnectMenu.vue";
import UserReceiver from "@/components/utils/binance/UserReceiver.vue";
export default {
  name: "Home",

  components: {
    TradingNavigationBar,
    StreamReceiver,
    GridLayout,
    GridItem,
    TickerPanel,
    ChartPanel,
    EmptyPanel,
    TradesPanel,
    ResizeObserver,
    OrderBookPanel,
    TestPanel,
    InformationPanel,
    OrderFormPanel,
    APIKeyConnectMenu,
    UserReceiver,
    OrderFormBottomBar,
  },

  data() {
    return {
      layouts: layoutGrid,
      layout: layoutGrid.lg,
      responsive: true,
      draggable: true,
      resizable: true,
      index: 0,

      checkAPILoading: false,
    };
  },
  mounted() {
    setMeta({ title: "Trading", description: "비트코인 선물 트레이딩" });
  },
  methods: {
    handleResize({ width, height, name }) {
      this.$refs[name][0].handleResize(width, height);
    },

    breakpointChangedEvent: function (newBreakpoint, newLayout) {
      // console.log('BREAKPOINT CHANGED breakpoint=', newBreakpoint, ', layout: ', newLayout
    },

    routerPush(routerName, index) {
      if (routerName === "") {
        alert("준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>
