var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.openOrders,"items-per-page":-1,"hide-default-footer":"","no-data-text":"","no-results-text":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.symbol))])])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.time).format("yy/MM/DD HH:MM:SS"))+" ")])]}},{key:"item.positionSide",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-bold",class:_vm.getPositionColor(item.side) + '--text'},[_vm._v(" "+_vm._s(item.side == "BUY" ? "OPEN" : "CLOSE")+" ")]),_c('span',{staticClass:"font-weight-bold",class:_vm.getPositionColor(item.side) + '--text'},[_vm._v(" "+_vm._s(item.positionSide)+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-bold body-2 error--text pa-1",attrs:{"small":"","text":"","depressed":""},on:{"click":function($event){return _vm.cancelOrder(item)}}},[_vm._v(" Cancel ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }