<template>
  <div>
    <v-card-title class="subtitle-2 pa-4 font-weight-bold white--text">
      <div class="vue-draggable-handle"></div>
      Test Panel
    </v-card-title>
    <v-card-text>
      <v-btn @click="testFunction" rounded large depressed color="primary"
        >Test Function</v-btn
      >
    </v-card-text>
  </div>
</template>

<script>
import BinanceConfig from "@/data/trading/user/binanceConfig.js";
// eslint-disable-next-line no-unused-vars
import binance from "@/APIs/binanceAPI";
const URL = "https://fapi.binance.com";
const APIKEY = BinanceConfig.APIKEY;
// eslint-disable-next-line no-unused-vars
const APISECRET = BinanceConfig.APISECRET;
export default {
  mounted() {
    // this.testFunction()
  },
  methods: {
    testFunction() {
      console.log("Test Start!");
      this.getUserBalance();
      this.$toasted.global.notice("Test End!");
    },
    async getUserBalance() {
      // console.info(await binance.futuresPositionInformation({ symbol: 'BTCUSDT' }))
      // console.info(await binance.futuresStartUserDataStream())
      // binance.futuresUserDataStream('EShHyXnFCJ6FcKtvU9O774bgd0KGsDLza9lKCVhYhUf3j8gGV4fUhxRiZw0bgSbn', console.log)
    },
    async getUserListenKey() {
      const q = "/fapi/v1/listenKey";
      await fetch(URL + q, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-MBX-APIKEY": APIKEY,
        },
      })
        .then((r) => r.json())
        .then((data) => {
          console.log(data.listenKey);
          //  = data.listenKey
        });
    },

    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
  },
};
</script>

<style></style>
