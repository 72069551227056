<template>
  <div></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import binance from "@/APIs/binanceAPI";
export default {
  beforeDestroy() {
    this.closeAllStream();
  },
  mounted() {
    this.initialize();
  },
  data() {
    return {
      wss: [],
    };
  },
  methods: {
    async initialize() {
      await this.GetTickerStream();
      await this.GetTradeStream();
      await this.GetMarkPriceStream();
      await this.checkAPIConnection();
    },

    async closeAllStream() {
      this.wss.forEach((ws) => {
        ws.close();
      });
    },

    async checkAPIConnection() {
      var apikey =
        this.$store.state.trading.binance.futures.user.data.API.APIKEY;
      var apiSecret =
        this.$store.state.trading.binance.futures.user.data.API.APISECRET;
      if (apikey === "" || apiSecret === "") return;

      await binance.binanceSetAPIKey([apikey, apiSecret]);
      await binance.futuresTestConnectivity({}, (r) => {
        if (r === "success") {
          this.$toasted.global.success("API Connected");
          this.$store.dispatch(
            "trading/binance/futures/user/SET_API_CONNECTION",
            true,
          );
        } else {
          this.$toasted.global.error(r);
          this.$store.dispatch(
            "trading/binance/futures/user/SET_API_CONNECTION",
            false,
          );
        }
      });
    },

    async GetTickerStream() {
      var ws = await binance.futuresTickerStream("BTCUSDT", (r) => {
        var data = {
          close: r.close,
          priceChange: r.priceChange,
          high: r.high,
          low: r.low,
          volume: r.volume,
          quoteVolume: r.quoteVolume,
        };
        this.$store.dispatch(
          "trading/binance/futures/stream/SET_TICKER_DATA",
          data,
        );
        // console.log(this.$store.getters['trading/binance/futures/stream/CURRENT_TICKER'])
      });
      this.wss.push(ws);
    },

    async GetTradeStream() {
      var ws = await binance.futuresAggTradeStream("BTCUSDT", (r) => {
        this.$store.dispatch(
          "trading/binance/futures/stream/PUSH_TRADE_DATA",
          r,
        );
      });
      this.wss.push(ws);
    },

    async GetMarkPriceStream() {
      var ws = await binance.futuresMarkPriceStream("BTCUSDT", (r) => {
        this.$store.dispatch("trading/binance/futures/stream/SET_MARK_DATA", r);
      });
      this.wss.push(ws);
    },
  },
};
</script>

<style></style>
