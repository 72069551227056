/* eslint-disable no-unused-vars */
export default {
  /**
   * Converts the futures liquidation stream data into a friendly object
   * @param {object} data - liquidation data callback data type
   * @return {object} - user friendly data type
   */
  fLiquidationConvertData(data) {
    const eventType = data.e;
    const eventTime = data.E;
    const {
      s: symbol,
      S: side,
      o: orderType,
      f: timeInForce,
      q: origAmount,
      p: price,
      ap: avgPrice,
      X: orderStatus,
      l: lastFilledQty,
      z: totalFilledQty,
      T: tradeTime,
    } = data.o;
    return {
      symbol,
      side,
      orderType,
      timeInForce,
      origAmount,
      price,
      avgPrice,
      orderStatus,
      lastFilledQty,
      totalFilledQty,
      eventType,
      tradeTime,
      eventTime,
    };
  },

  /**
   * Converts the futures ticker stream data into a friendly object
   * @param {object} data - user data callback data type
   * @return {object} - user friendly data type
   */
  fTickerConvertData(data) {
    const friendlyData = (data) => {
      const {
        e: eventType,
        E: eventTime,
        s: symbol,
        p: priceChange,
        P: percentChange,
        w: averagePrice,
        c: close,
        Q: closeQty,
        o: open,
        h: high,
        l: low,
        v: volume,
        q: quoteVolume,
        O: openTime,
        C: closeTime,
        F: firstTradeId,
        L: lastTradeId,
        n: numTrades,
      } = data;
      return {
        eventType,
        eventTime,
        symbol,
        priceChange,
        percentChange,
        averagePrice,
        close,
        closeQty,
        open,
        high,
        low,
        volume,
        quoteVolume,
        openTime,
        closeTime,
        firstTradeId,
        lastTradeId,
        numTrades,
      };
    };
    if (Array.isArray(data)) {
      const result = [];
      for (const obj of data) {
        result.push(friendlyData(obj));
      }
      return result;
    }
    return friendlyData(data);
  },

  /**
   * Converts the futures miniTicker stream data into a friendly object
   * @param {object} data - user data callback data type
   * @return {object} - user friendly data type
   */
  fMiniTickerConvertData(data) {
    const friendlyData = (data) => {
      const {
        e: eventType,
        E: eventTime,
        s: symbol,
        c: close,
        o: open,
        h: high,
        l: low,
        v: volume,
        q: quoteVolume,
      } = data;
      return {
        eventType,
        eventTime,
        symbol,
        close,
        open,
        high,
        low,
        volume,
        quoteVolume,
      };
    };
    if (Array.isArray(data)) {
      const result = [];
      for (const obj of data) {
        result.push(friendlyData(obj));
      }
      return result;
    }
    return friendlyData(data);
  },

  /**
   * Converts the futures bookTicker stream data into a friendly object
   * @param {object} data - user data callback data type
   * @return {object} - user friendly data type
   */
  fBookTickerConvertData(data) {
    const {
      u: updateId,
      s: symbol,
      b: bestBid,
      B: bestBidQty,
      a: bestAsk,
      A: bestAskQty,
    } = data;
    return {
      updateId,
      symbol,
      bestBid,
      bestBidQty,
      bestAsk,
      bestAskQty,
    };
  },

  /**
   * Converts the futures markPrice stream data into a friendly object
   * @param {object} data - user data callback data type
   * @return {object} - user friendly data type
   */
  fMarkPriceConvertData(data) {
    const friendlyData = (data) => {
      const {
        e: eventType,
        E: eventTime,
        s: symbol,
        p: markPrice,
        r: fundingRate,
        T: fundingTime,
      } = data;
      return {
        eventType,
        eventTime,
        symbol,
        markPrice,
        fundingRate,
        fundingTime,
      };
    };
    if (Array.isArray(data)) {
      const result = [];
      for (const obj of data) {
        result.push(friendlyData(obj));
      }
      return result;
    }
    return friendlyData(data);
  },

  /**
   * Converts the futures aggTrade stream data into a friendly object
   * @param {object} data - user data callback data type
   * @return {object} - user friendly data type
   */
  fAggTradeConvertData(data) {
    const friendlyData = (data) => {
      const {
        e: eventType,
        E: eventTime,
        s: symbol,
        a: aggTradeId,
        p: price,
        q: amount,
        f: firstTradeId,
        l: lastTradeId,
        T: timestamp,
        m: maker,
      } = data;
      return {
        eventType,
        eventTime,
        symbol,
        aggTradeId,
        price,
        amount,
        total: price * amount,
        firstTradeId,
        lastTradeId,
        timestamp,
        maker,
      };
    };
    if (Array.isArray(data)) {
      const result = [];
      for (const obj of data) {
        result.push(friendlyData(obj));
      }
      return result;
    }
    return friendlyData(data);
  },

  /**
   * TODO: Change completely
   * Converts the futures kline candle data into a friendly object
   * @param {object} data - user data callback data type
   * @return {object} - user friendly data type
   */
  fKlineConvertData(data) {
    const friendlyData = (data) => {
      const {
        e: eventType,
        E: eventTime,
        s: symbol,
        k,
        // t: startTime,
        // T: closeTime,
        // s: ksymbol,
        // i: interval,
        // f: firstTradeId,
        // L: lastTradeId,
        // o: open,
        // c: close,
        // h: high,
        // l: low,
        // v: volume,
        // n: number,
        // x: closed,
        // q: quoteVolume,
        // V: takerBaseVolume,
        // Q: takerQuoteVolume,
        // B: ignore
      } = data;
      return {
        eventType,
        eventTime,
        symbol,
        k,
      };
    };
    if (Array.isArray(data)) {
      const result = [];
      for (const obj of data) {
        result.push(friendlyData(obj));
      }
      return result;
    }
    return friendlyData(data);
  },
};
