<template>
  <div>
    <v-card-title class="subtitle-2 pa-2 font-weight-bold">
      <!-- <div class="vue-draggable-handle"></div> -->
      <v-item-group v-model="tab" mandatory>
        <span v-for="item in items" :key="item.tab">
          <v-item v-slot="{ active, toggle }">
            <v-btn
              class="font-weight-bold body-2"
              :color="active ? 'primary' : ''"
              @click="toggle"
              text
            >
              {{ item.tab }}
            </v-btn>
          </v-item>
        </span>
      </v-item-group>
    </v-card-title>
    <v-card-text
      class="text-center"
      v-if="
        !$store.getters['auth/user/GET_USER'] ||
        !$store.getters['trading/preferance/system/api/API_LOADED']
      "
    >
      <v-btn
        @click="$router.push({ name: 'Login' })"
        :ripple="false"
        class="body-2 mx-auto"
        text
        color="primary"
        >Log In or Register Now</v-btn
      >
    </v-card-text>
    <v-card-text v-else>
      <v-tabs-items class="transparent" v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <component :is="item.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </div>
</template>

<script>
import Assets from "./Assets.vue";
import OpenOrder from "./OpenOrder.vue";
import Position from "./Position.vue";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Assets, Position, OpenOrder },
  computed: {
    positionLength: {
      get() {
        return this.$store.getters[
          "trading/binance/futures/user/CURRENT_POSITION_INFORMATION"
        ].length;
      },
    },
    orderLength: {
      get() {
        return this.$store.getters["trading/binance/futures/user/OPEN_ORDERS"]
          .length;
      },
    },
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: "Position", component: "Position", value: 0 },
        { tab: "Open Order", component: "OpenOrder", value: 0 },
        // { tab: 'Assets', component: 'Assets', value: '' }
      ],
    };
  },
  methods: {
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
  },
};
</script>

<style></style>
