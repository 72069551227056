const layoutGrid = {
  xs: [
    {
      x: 0,
      y: 0,
      w: 4,
      h: 12,
      i: "1",
      static: false,
      resizable: true,
      component: "ChartPanel",
      name: "chart_panel",
    },
    {
      x: 0,
      y: 22,
      w: 4,
      h: 12,
      i: "5",
      static: false,
      resizable: true,
      component: "InformationPanel",
      name: "data_panel",
      title: "",
    },
  ],
  sm: [
    {
      x: 0,
      y: 0,
      w: 6,
      h: 10,
      i: "1",
      static: false,
      resizable: true,
      component: "ChartPanel",
      name: "chart_panel",
    },

    {
      x: 0,
      y: 27,
      w: 6,
      h: 10,
      i: "5",
      static: false,
      resizable: true,
      component: "InformationPanel",
      name: "data_panel",
      title: "",
    },
  ],
  md: [
    {
      x: 0,
      y: 0,
      w: 6,
      h: 16,
      i: "1",
      static: false,
      resizable: true,
      component: "ChartPanel",
      name: "chart_panel",
    },

    {
      x: 6,
      y: 0,
      w: 4,
      h: 32,
      i: "4",
      static: false,
      resizable: true,
      component: "OrderFormPanel",
      name: "order_panel",
      title: "Control Panel",
    },

    {
      x: 0,
      y: 16,
      w: 6,
      h: 10,
      i: "5",
      static: false,
      resizable: true,
      component: "InformationPanel",
      name: "data_panel",
      title: "",
    },
  ],
  lg: [
    {
      x: 0,
      y: 0,
      w: 9,
      h: 20,
      i: "1",
      static: false,
      resizable: true,
      component: "ChartPanel",
      name: "chart_panel",
    },

    {
      x: 0,
      y: 20,
      w: 9,
      h: 10,
      i: "5",
      static: false,
      resizable: true,
      component: "InformationPanel",
      name: "data_panel",
      title: "",
    },
    {
      x: 9,
      y: 0,
      w: 3,
      h: 30,
      i: "4",
      static: false,
      resizable: true,
      component: "OrderFormPanel",
      name: "order_panel",
      title: "Control Panel",
    },
  ],
};
export { layoutGrid };
