<template>
  <v-container app fluid>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-col cols="5">
        <span class="font-weight-bold body-1">
          BTC/USDT
          <div class="caption">Perpetual</div>
        </span>

        <span :class="getPNLColor + '--text'" class="font-weight-bold title">
          {{ numberWithCommas(parseFloat(tickerData[0].value).toFixed(2)) }}
          <div
            :class="getTickerColor + '--text'"
            class="font-weight-bold body-2"
          >
            {{ numberWithCommas(parseFloat(tickerData[1].value).toFixed(2)) }}
            <span>
              ({{
                numberWithCommas(
                  parseFloat(
                    tickerData[1].value / (tickerData[0].value / 100),
                  ).toFixed(2),
                )
              }}%)
            </span>
          </div>
        </span>
      </v-col>

      <v-col class="px-0 align-end" cols="7">
        <v-container fluid app>
          <v-row>
            <v-col
              cols="6"
              class="pa-0"
              v-for="(item, i) in tickerData.splice(2)"
              :key="i"
              :slot-scope="i"
            >
              <v-card class="transparent pa-0" flat>
                <v-card-subtitle class="caption pa-0">
                  {{ item.title }}
                </v-card-subtitle>
                <v-card-title class="caption pa-0">
                  {{ nFormatter(parseFloat(item.value).toFixed(2), 2) }}
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row class="pa-4 align-center" v-else>
      <span class="font-weight-bold body-1">
        BTC/USDT
        <div class="caption">Perpetual</div>
      </span>
      <span :class="pnlColor + '--text'" class="font-weight-bold body-1 ml-4">
        {{ numberWithCommas(parseFloat(tickerData[0].value).toFixed(2)) }}

        <div :class="getTickerColor + '--text'" class="font-weight-bold body-2">
          {{ numberWithCommas(parseFloat(tickerData[1].value).toFixed(2)) }}
          <span>
            ({{
              numberWithCommas(
                parseFloat(
                  tickerData[1].value / (tickerData[0].value / 100),
                ).toFixed(2),
              )
            }}%)
          </span>
        </div>
      </span>
      <span class="mx-2">
        <v-slide-group v-if="$vuetify.breakpoint.mdAndUp" show-arrows>
          <v-slide-item
            v-for="(item, i) in tickerData.slice(2)"
            :key="i"
            :slot-scope="i"
          >
            <v-card v-if="item.title" class="transparent" flat>
              <v-card-subtitle class="caption py-0">
                {{ item.title }}
              </v-card-subtitle>
              <v-card-title class="caption py-0">
                {{ numberWithCommas(parseFloat(item.value).toFixed(2)) }}
                <span v-if="i == 1">
                  ({{
                    numberWithCommas(
                      parseFloat(
                        item.value / (tickerData[0].value / 100),
                      ).toFixed(2),
                    )
                  }}%)
                </span>
              </v-card-title>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  // 34074.94
  // Mark 34068.92
  // Index 34,077.54,
  // Funding/Counddown 0.0100% 00:33:19
  // 24h Change -1,341.71 -3.79%
  // 24 high 35,955.55
  // 24 Low 34,000.00
  // 24h Volume(BTC) 545,633.568
  // 24h Volume(USDT) 19,113,426,306.63
  data() {
    return {
      pnlColor: "",
      tickerSignColor: "",
    };
  },
  mounted() {},
  computed: {
    tickerData: {
      get() {
        var ticker =
          this.$store.state.trading.binance.futures.stream.data.ticker;
        var data = [
          { title: "Close", value: ticker.close },
          { title: "24h Change", value: ticker.priceChange },
          { title: "High", value: ticker.high },
          { title: "Low", value: ticker.low },
          { title: "Volume(BTC)", value: ticker.volume },
          { title: "Volume(USDT)", value: ticker.quoteVolume },
        ];
        return data;
      },
    },
    getTickerColor: {
      get() {
        return this.tickerSignColor;
      },
      set(v) {
        if (v > 0) this.tickerSignColor = "bull";
        else if (v < 0) this.tickerSignColor = "bear";
        else this.tickerSignColor = "secondary";
      },
    },
    getPNLColor: {
      get() {
        return this.pnlColor;
      },
      set(v) {
        if (v > 0) this.pnlColor = "bull";
        else if (v < 0) this.pnlColor = "bear";
        else this.pnlColor = "secondary";
      },
    },
  },
  watch: {
    tickerData: function (newVal, oldVal) {
      this.getPNLColor = Math.sign(newVal[0].value - oldVal[0].value).toFixed(
        2,
      );
      this.getTickerColor = parseFloat(newVal[1].value);
    },
  },
  methods: {
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    nFormatter(num, digits) {
      var si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];
      var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var i;
      // for negative value is work
      for (i = si.length - 1; i > 0; i--) {
        if (Math.abs(num) >= si[i].value) {
          break;
        }
      }
      return (
        (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
      );
    },
  },
};
</script>

<style></style>
