<template>
  <div>
    <v-card-title class="pa-4 subtitle-2">
      <div class="vue-draggable-handle"></div>
      Trades
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="$store.state.binance.futures.stream.data.trade"
        :items-per-page="-1"
        hide-default-footer
        dense
        class="transparent"
      >
        <template v-slot:[`item.price`]="{ item }">
          <span
            :class="getMakerTakerColor(item.maker) + '--text'"
            class="caption font-weight-bold"
          >
            {{ numberWithCommas(parseFloat(item.price).toFixed(2)) }}
          </span>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <span class="caption">
            {{ parseFloat(item.amount).toFixed(4) }}
          </span>
        </template>

        <template v-slot:[`item.timestamp`]="{ item }">
          <span class="caption">
            {{ $moment(parseInt(item.timestamp)).format("hh:mm:ss") }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
  },
  mounted() {},

  beforeDestroy() {},
  data() {
    return {
      tradeStream: [],
      reversedStream: [],
      headers: [
        {
          text: "Price(USDT)",
          align: "start",
          sortable: false,
          class: "caption",
          value: "price",
          width: 10,
        },
        {
          text: "Amount(BTC)",
          align: "end",
          sortable: false,
          class: "caption",
          value: "amount",
          width: 0,
        },
        {
          text: "Time",
          align: "end",
          sortable: false,
          class: "caption",
          value: "timestamp",
          width: 10,
        },
      ],
    };
  },
  methods: {
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },

    getMakerTakerColor(m) {
      if (m) {
        return "bear";
      } else {
        return "bull";
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
