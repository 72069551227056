<template>
  <div>
    <v-app-bar
      color="background"
      :dark="$vuetify.theme.dark"
      flat
      class="secondary"
      app
    >
      <v-app-bar-title class="mr-5">
        <v-img
          @click="routerPush('Home')"
          style="cursor: pointer"
          contain
          width="111"
          :style="$vuetify.theme.dark ? '' : 'filter: brightness(1) invert(1);'"
          :src="require('@/assets/logos/woozLabs_white.svg')"
        >
        </v-img>
      </v-app-bar-title>

      <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">
        <span style="" class="font-weight-bold subtitle-1">
          {{ $moment().format("MM월 DD일 (ddd)") }} - {{ currentTime }}
        </span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-alert type="error" dense class="ma-0">
        Refactoring is on process. This page will not work.
      </v-alert>
      <!-- API KEY -->
      <v-menu
        v-if="
          $vuetify.breakpoint.mdAndUp && $store.getters['auth/user/GET_USER']
        "
        offset-y
        left
        transition="scale-transition"
        origin="right top"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-2" v-bind="attrs" v-on="on" icon>
            <v-icon color="onSurface">mdi-qrcode</v-icon>
          </v-btn>
        </template>

        <APIKeyConnectMenu />
      </v-menu>

      <!-- LOGIN ICON -->
      <v-btn
        v-if="
          !$store.getters['auth/user/GET_USER'] && $vuetify.breakpoint.mdAndUp
        "
        @click="routerPush('Login')"
        class="body-2 font-weight-bold mx-2 primary"
        text
      >
        Log In
      </v-btn>
      <more-menu>
        <template v-slot:moreMenuTrigger="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            width="40"
            height="40"
            class="background mx-2"
            icon
            depressed
            :ripple="false"
          >
            <v-avatar size="36" color="surface">
              <v-img :src="$store.getters['auth/user/GET_USER'].photoURL" />
            </v-avatar>
          </v-btn>
        </template>
      </more-menu>

      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        class="mx-2"
        @click="toggleDrawer"
        icon
        color="secondary"
      >
        <v-img
          height="24"
          contain
          :style="$vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''"
          :src="require('@/assets/icons/hamburger.svg')"
        >
        </v-img>
      </v-btn>
    </v-app-bar>
    <navigation-drawer :drawer="drawer" @toggleDrawer="toggleDrawer" />
  </div>
</template>

<script>
import MoreMenu from "@/components/Page/Menu/MoreMenu.vue";
import NavigationDrawer from "@/components/Page/NavigationBar/NavigationDrawer.vue";
import APIKeyConnectMenu from "../Card/APIKeyConnectMenu.vue";
export default {
  components: {
    MoreMenu,
    APIKeyConnectMenu,
    NavigationDrawer,
  },

  computed: {
    language: {
      get() {
        return this.$store.getters["page/settings/CURRENT_LANGUAGE"];
      },
      set(val) {
        this.$store.dispatch("page/settings/SET_LANGUAGE", val);
        this.$i18n.locale = val;
      },
    },
  },
  created() {
    this.currentTime = this.$moment().format("LTS");
    setInterval(() => {
      this.currentTime = this.$moment().format("LTS");
    }, 1000);
  },
  mounted() {},
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    toggleLanguage() {
      if (this.$i18n.locale === "en") {
        this.language = "ko";
      } else {
        this.language = "en";
      }
    },
    routerPush(routerName, index) {
      if (routerName === "") {
        alert("준비중입니다.");
        this.selectedItem = index;
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
  data() {
    return {
      currentTime: this.$moment().format("HH:mm:ss"),
      drawer: false,
      items: [
        {
          title: "🤖 Wooz Labs",
          to: "",
        },
        {
          active: false,
          items: [
            { title: "📈 Trading AI (WIP)", to: "Trading" },
            { title: "..." },
          ],
          title: "💡 Tech & Service",
        },
        {
          title: "🖥 Records",
          to: "",
        },
      ],
    };
  },
};
</script>

<style></style>
