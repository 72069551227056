<template>
  <div>
    <v-card-title class="pa-4 subtitle-2">
      <div class="vue-draggable-handle"></div>
      Order Book
    </v-card-title>
    <v-card-text class="pa-0">
      <!-- <v-list dense subheader>
      <v-subheader>Recent chat</v-subheader>

      <v-list-item
        v-for="chat in recent"
        :key="chat.title"
      >
        <v-list-item-avatar>
          <v-img
            :alt="`${chat.title} avatar`"
            :src="chat.avatar"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="chat.title"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon :color="chat.active ? 'deep-purple accent-4' : 'grey'">
            mdi-message-outline
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list> -->
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {},

  beforeDestroy() {},
  data() {
    return {
      recent: [
        {
          active: true,
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Jason Oner",
        },
        {
          active: true,
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: "Mike Carlson",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Cindy Baker",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Ali Connors",
        },
      ],
      orderbook: {
        lastUpdateId: 160, // Last update ID
        bids: [
          // Bids to be updated
          [
            "0.0024", // Price level to be updated
            "10", // Quantity
          ],
        ],
        asks: [
          // Asks to be updated
          [
            "0.0026", // Price level to be updated
            "100", // Quantity
          ],
        ],
      },
    };
  },
  methods: {
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },

    compareValueColor(a, b) {
      if (a > b) {
        return "bull";
      } else if (a === b) {
        return "primary";
      } else {
        return "blue";
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
