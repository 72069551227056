<template>
  <v-card class="overflow-hidden" rounded="lg" outlined>
    <v-card-title class="body-2 font-weight-bold">
      API Key를 입력해주세요.
      <v-tooltip transition="fade-transition" right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-2" v-bind="attrs" v-on="on" small
            >mdi-help-circle-outline
          </v-icon>
        </template>
        <span class="caption" v-html="'asdf'"></span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="py-0">
      <v-subheader class="caption pa-0" style="height: 24px"
        >API Key</v-subheader
      >
      <v-text-field
        v-model="apikey"
        label=""
        required
        hide-details
        outlined
        dense
        flat
      >
      </v-text-field>

      <v-subheader class="caption pa-0" style="height: 24px"
        >Secret Key</v-subheader
      >
      <v-text-field
        class="mb-2"
        v-model="apiSecret"
        label=""
        required
        hide-details
        outlined
        dense
        flat
      >
      </v-text-field>
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn
        :loading="checkAPILoading"
        @click="checkAPIConnection()"
        small
        depressed
        class="primary caption"
      >
        연결
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import binance from "@/APIs/binanceAPI";
export default {
  computed: {
    apikey: {
      get() {
        return this.$store.state.trading.binance.futures.user.data.API.APIKEY;
      },
      set(value) {
        this.$store.dispatch("trading/binance/futures/user/SET_API_KEY", value);
      },
    },
    apiSecret: {
      get() {
        return this.$store.state.trading.binance.futures.user.data.API
          .APISECRET;
      },
      set(value) {
        this.$store.dispatch(
          "trading/binance/futures/user/SET_API_SECRET",
          value,
        );
      },
    },
  },
  data() {
    return {
      checkAPILoading: false,
    };
  },
  mounted() {},
  methods: {
    async checkAPIConnection() {
      if (this.apikey === "" || this.apiSecret === "") return;
      this.checkAPILoading = true;

      await binance.binanceSetAPIKey([this.apikey, this.apiSecret]);
      await binance.futuresTestConnectivity({}, (r) => {
        if (r === "success") {
          this.$toasted.global.success("API Connected");
          this.$store.dispatch(
            "trading/binance/futures/user/SET_API_CONNECTION",
            true,
          );
        } else {
          this.$toasted.global.error(r);
          this.$store.dispatch(
            "trading/binance/futures/user/SET_API_CONNECTION",
            false,
          );
        }
      });
      this.checkAPILoading = false;
    },
  },
};
</script>

<style></style>
