<template>
  <div>Assets</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
  },
};
</script>

<style></style>
