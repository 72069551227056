<template>
  <div>
    <v-card-title class="subtitle-2 pa-4 font-weight-bold white--text">
      <div class="vue-draggable-handle"></div>
      Empty Panel
    </v-card-title>
  </div>
</template>

<script>
export default {
  methods: {
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
  },
};
</script>

<style></style>
