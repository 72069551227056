<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="openOrders"
      :items-per-page="-1"
      hide-default-footer
      no-data-text=""
      no-results-text=""
      disable-sort
      dense
    >
      <template v-slot:[`item.symbol`]="{ item }">
        <div>
          <span class="font-weight-bold">{{ item.symbol }}</span>
        </div>
      </template>

      <template v-slot:[`item.time`]="{ item }">
        <div>
          {{ $moment(item.time).format("yy/MM/DD HH:MM:SS") }}
        </div>
      </template>

      <template v-slot:[`item.positionSide`]="{ item }">
        <div>
          <span
            :class="getPositionColor(item.side) + '--text'"
            class="font-weight-bold"
          >
            {{ item.side == "BUY" ? "OPEN" : "CLOSE" }}
          </span>
          <span
            :class="getPositionColor(item.side) + '--text'"
            class="font-weight-bold"
          >
            {{ item.positionSide }}
          </span>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          @click="cancelOrder(item)"
          class="font-weight-bold body-2 error--text pa-1"
          small
          text
          depressed
        >
          Cancel
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import binance from "@/APIs/binanceAPI";
export default {
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    openOrders: {
      get() {
        return this.$store.getters["trading/binance/futures/user/OPEN_ORDERS"];
      },
    },
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Symbol",
          class: "font-weight-regular",
          align: "start",
          value: "symbol",
        },
        {
          text: "Time",
          class: "font-weight-regular",
          value: "time",
        },
        // {
        //   text: 'Type',
        //   class: 'font-weight-regular',
        //   value: 'type'
        // },
        {
          text: "Side",
          class: "font-weight-regular",
          value: "positionSide",
        },
        {
          text: "Price",
          class: "font-weight-regular",
          value: "price",
        },
        {
          text: "Amount",
          class: "font-weight-regular",
          value: "origQty",
        },
        {
          text: "Filled",
          class: "font-weight-regular",
          value: "executedQty",
        },
        {
          text: "Reduce Only",
          class: "font-weight-regular",
          value: "reduceOnly",
        },
        {
          text: "Cancel All",
          class: "font-weight-regular",
          value: "action",
        },
      ],
      position: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {},
  methods: {
    cancelOrder(item) {
      binance
        .futuresOrderCancel(item.symbol, item.orderId)
        .then((r) => {
          this.$store.dispatch(
            "trading/binance/futures/user/DELETE_OPEN_ORDER",
            r.orderId,
          );
          this.$toasted.global.success("Order Canceled");
        })
        .catch((err) => {
          this.$toasted.global.error(err);
        });
    },
    getPositionColor(p) {
      if (p === "BUY") {
        return "bull";
      } else if (p === "SELL") {
        return "bear";
      } else {
        return "secondary";
      }
    },
    numberWithCommas(x) {
      if (typeof x === "string") {
        x = parseFloat(x).toFixed(2);
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
  },
};
</script>

<style></style>
