/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import Vue from "vue";
import ConvertData from "../utils/convert_to_friendly";
import BinanceConfig from "../data/trading/user/binanceConfig";
import crypto from "crypto";
import { func } from "assert-plus";
import { call } from "file-loader";
let base = "https://fapi.binance.com/fapi/";
let wapi = "https://api.binance.com/wapi/";
let sapi = "https://api.binance.com/sapi/";
let fapi = "https://fapi.binance.com/fapi/";
let fapiTest = "https://testnet.binancefuture.com/fapi/";
let stream = "wss://stream.binance.com:9443/ws/";
let fstreamSingle = "wss://fstream.binance.com/ws/";
let fstream = "wss://fstream.binance.com/stream?streams=";
let combineStream = "wss://stream.binance.com:9443/stream?streams=";
const contentType = "x-www-form-urlencoded";
let subscriptions = {};
let futuresSubscriptions = {};
let futuresTicks = {};
let futuresMeta = {};
let futuresRealZ = {};
let futuresKlineQueue = {};
let depthCache = {};
let depthCacheContext = {};
let ohlcLatest = {};
let klineQueue = {};
const ohlc = {};

function initialize() {}
function setOptions(opt = {}, callback = false) {
  if (typeof opt === "string") {
    // Pass json config filename
    // options = JSON.parse(file.readFileSync(opt))
  } else options = opt;
  if (typeof options.recvWindow === "undefined")
    options.recvWindow = default_options.recvWindow;
  if (typeof options.useServerTime === "undefined")
    options.useServerTime = default_options.useServerTime;
  if (typeof options.reconnect === "undefined")
    options.reconnect = default_options.reconnect;
  if (typeof options.test === "undefined") options.test = default_options.test;
  if (typeof options.log === "undefined") options.log = default_options.log;
  // if (typeof options.success === 'undefined') options.success = Vue.prototype.$toasted.global.success
  // if (typeof options.error === 'undefined') options.error = Vue.prototype.$toasted.global.error
  if (typeof options.verbose === "undefined")
    options.verbose = default_options.verbose;
  if (typeof options.urls !== "undefined") {
    const { urls } = options;
    if (typeof urls.base === "string") base = urls.base;
    if (typeof urls.wapi === "string") wapi = urls.wapi;
    if (typeof urls.sapi === "string") sapi = urls.sapi;
    if (typeof urls.fapi === "string") fapi = urls.fapi;
    if (typeof urls.fapiTest === "string") fapiTest = urls.fapiTest;
    if (typeof urls.stream === "string") stream = urls.stream;
    if (typeof urls.combineStream === "string")
      combineStream = urls.combineStream;
    if (typeof urls.fstream === "string") fstream = urls.fstream;
    if (typeof urls.fstreamSingle === "string")
      fstreamSingle = urls.fstreamSingle;
  }
  if (options.useServerTime) {
    apiRequest(fapi + "v1/time", {}, (r) => {
      info.timeOffset = r.serverTime - new Date().getTime();
      // options.log("server time set: ", response.serverTime, info.timeOffset);
      if (callback) callback();
    });
  } else if (callback) callback();
}

function setAPIOption([APIKEY, APISECRET], callback = false) {
  options.APIKEY = APIKEY;
  options.APISECRET = APISECRET;
}

const default_options = {
  APIKEY: BinanceConfig.APIKEY,
  APISECRET: BinanceConfig.APISECRET,
  // APIKEY: this.$store.state.binance.futures.user.data.APIKEY,
  // APISECRET: this.$store.state.binance.futures.user.data.APISECRET,
  recvWindow: 5000,
  useServerTime: false,
  reconnect: false,
  verbose: false,
  log: console.info,
  success: console.info,
  error: console.info,
  // log: Vue.prototype.$toasted.global.notice
};
let options = default_options;
const info = { timeOffset: 0 };
let socketHeartbeatInterval = null;
if (options) setOptions(options);

function request(url, params = {}, callback = false, opt = {}) {
  let hasParams = Object.keys(params).length;
  if (!url || typeof url !== "string") url = opt.url;
  if (!hasParams && opt.qs) {
    params = opt.qs;
    hasParams = Object.keys(params).length;
  }
  if (hasParams) url = `${url}?${new URLSearchParams(params).toString()}`;
  if (!url) throw Error(`axios error: ${url}`);
  if (options.verbose) console.info("request", url, params, opt);
  // opt.url = url;
  opt.method = !opt.method ? "get" : opt.method;
  opt.baseURL = !opt.baseURL ? base : opt.baseURL;
  fetch(url, opt)
    .then((response) => {
      if (callback) {
        response.json().then((data) => callback(data));
      }
    })
    .catch(function (error) {
      if (error.response) console.warn(error.response.data);
      throw error.message;
    });
}

/**
 * Checks to see of the object is iterable
 * @param {object} obj - The object check
 * @return {boolean} true or false is iterable
 */
const isIterable = (obj) => {
  if (!obj) return false;
  return Symbol.iterator in Object(obj);
  // return typeof obj[Symbol.iterator] === 'function';
};

// if ( Object.keys( params ).length ) url = `${ url }?${ new URLSearchParams( params ).toString() }`;
function reqObj(url, data = {}, method = "GET", key) {
  return {
    url: url,
    json: data, // qs
    method,
    timeout: options.recvWindow,
    headers: {
      "Content-type": contentType,
      "X-MBX-APIKEY": key || "",
    },
  };
}

const reqObjPOST = (url, data = {}, method = "POST", key) => ({
  url: url,
  json: data, // form: data,
  method,
  timeout: options.recvWindow,
  headers: {
    "Content-type": contentType,
    "X-MBX-APIKEY": key || "",
  },
});

/**
 * Create a http request to the public API
 * @param {string} url - The http endpoint
 * @param {object} data - The data to send
 * @param {function} callback - The callback method to call
 * @param {string} method - the http method
 * @return {undefined}
 */
const publicRequest = (url, data = {}, callback, method = "GET") => {
  if (Object.keys(data).length)
    url = `${url}?${new URLSearchParams(data).toString()}`;
  if (options.verbose) console.info("publicRequest", url, data, method);
  let opt = reqObj(url, data, method);
  request(url, {}, callback, opt);
};
const makeQueryString = (q) =>
  Object.keys(q)
    .reduce((a, k) => {
      if (q[k] !== undefined) {
        a.push(k + "=" + encodeURIComponent(q[k]));
      }
      return a;
    }, [])
    .join("&");

function apiRequest(url, data = {}, callback, method = "GET") {
  if (Object.keys(data).length)
    url = `${url}?${new URLSearchParams(data).toString()}`;
  if (options.verbose) console.info("apiRequest", url, data, method);
  if (!options.APIKEY) throw Error("apiRequest: Invalid API Key");
  let opt = reqObj(url, data, method, options.APIKEY);
  request(opt.url, {}, callback, opt);
}

const promiseRequest = async (url, data = {}, flags = {}) => {
  // if ( Object.keys( params ).length ) url = `${ url }?${ new URLSearchParams( params ).toString() }`;
  if (options.verbose) console.info("promiseRequest", url, data, flags);
  return new Promise((resolve, reject) => {
    let query = "";
    let headers = {
      "Content-type": "application/x-www-form-urlencoded",
      "X-MBX-APIKEY": options.APIKEY,
    };
    if (typeof flags.method === "undefined") flags.method = "GET"; // GET POST PUT DELETE
    if (typeof flags.type === "undefined") flags.type = false;
    // TRADE, SIGNED, MARKET_DATA, USER_DATA, USER_STREAM
    else {
      if (typeof data.recvWindow === "undefined")
        data.recvWindow = options.recvWindow;
      headers["X-MBX-APIKEY"] = options.APIKEY;
      if (!options.APIKEY) return console.Error("Invalid API Key");
    }
    let baseURL = typeof flags.base === "undefined" ? base : flags.base;
    if (options.test && baseURL === fapi) baseURL = fapiTest;
    let opt = {
      headers,
      url: baseURL + url,
      method: flags.method,
      timeout: options.recvWindow,
      followAllRedirects: true,
    };
    if (
      flags.type === "SIGNED" ||
      flags.type === "TRADE" ||
      flags.type === "USER_DATA"
    ) {
      if (!options.APISECRET) return reject(Error("Invalid API Secret"));
      data.timestamp = new Date().getTime() + info.timeOffset;
      query = makeQueryString(data);

      data.signature = crypto
        .createHmac("sha256", options.APISECRET)
        .update(query)
        .digest("hex"); // HMAC hash header
      opt.url = `${baseURL}${url}?${query}&signature=${data.signature}`;
    }
    opt.form = data;
    // console.log(opt)
    try {
      request(
        false,
        {},
        (data) => {
          // response
          // if ( error ) return reject( error );
          try {
            return resolve(data);
            // return resolve ( response.data );
            /* if ( !error && response.statusCode == 200 ) return resolve( response.data );
                  if ( typeof error.response.status !== 'undefined' ) {
                      return resolve( response.json() );
                  } */
            // return reject( response.data );
          } catch (err) {
            return reject(Error(`promiseRequest error #${err.statusCode}`));
          }
        },
        opt,
      );
    } catch (err) {
      return reject(err);
    }
  });
};

// const MD5 = new Hashes.MD5(); const openState = 1

/**
 * Futures heartbeat code with a shared single interval tick
 * @return {undefined}
 */
const futuresSocketHeartbeat = () => {
  /* Sockets removed from subscriptions during a manual terminate()
       will no longer be at risk of having functions called on them */
  // for (let endpointId in futuresSubscriptions) {
  //   const ws = futuresSubscriptions[endpointId]
  //   if (ws.isAlive) {
  //     ws.isAlive = false
  //     // TODO: Fix heartbeat. Browser client can't send pings
  //     // if ( ws.readyState === openState ) ws.send( '{"ping": true}' );
  //   } else {
  //     if (options.verbose) options.log(`Terminating zombie futures WebSocket: ${ws.endpoint}`)
  //     if (ws.readyState === openState) ws.close()
  //   }
  // }
};

/**
 * Called when a futures socket is opened, subscriptions are registered for later reference
 * @param {function} openCallback - a callback function
 * @return {undefined}
 */
const handleFuturesSocketOpen = function (openCallback) {
  this.isAlive = true;
  if (Object.keys(futuresSubscriptions).length === 0) {
    socketHeartbeatInterval = setInterval(futuresSocketHeartbeat, 30000);
  }
  futuresSubscriptions[this.endpoint] = this;
  if (typeof openCallback === "function") openCallback(this.endpoint);
};

/**
 * Called when futures websocket is closed, subscriptions are de-registered for later reference
 * @param {boolean} reconnect - true or false to reconnect the socket
 * @param {string} code - code associated with the socket
 * @param {string} reason - string with the response
 * @return {undefined}
 */
const handleFuturesSocketClose = function (reconnect, code, reason) {
  delete futuresSubscriptions[this.endpoint];
  if (futuresSubscriptions && Object.keys(futuresSubscriptions).length === 0) {
    clearInterval(socketHeartbeatInterval);
  }
  options.log(
    "Futures WebSocket closed: " +
      this.endpoint +
      (code ? " (" + code + ")" : "") +
      (reason ? " " + reason : ""),
  );
  if (options.reconnect && this.reconnect && reconnect) {
    if (this.endpoint && parseInt(this.endpoint.length, 10) === 60)
      options.log("Futures account data WebSocket reconnecting...");
    else
      options.log("Futures WebSocket reconnecting: " + this.endpoint + "...");
    try {
      reconnect();
    } catch (error) {
      options.log("Futures WebSocket reconnect error: " + error.message);
    }
  }
};

/**
 * Called when a futures websocket errors
 * @param {object} error - error object message
 * @return {undefined}
 */
const handleFuturesSocketError = function (error) {
  options.log(
    "Futures WebSocket error: " +
      this.endpoint +
      (error.code ? " (" + error.code + ")" : "") +
      (error.message ? " " + error.message : ""),
  );
};

const futuresSubscribeSingle = function (endpoint, callback, params = {}) {
  if (typeof params === "boolean") params = { reconnect: params };
  if (!params.reconnect) params.reconnect = false;
  if (!params.openCallback) params.openCallback = false;
  if (!params.id) params.id = false;
  let ws = new WebSocket(fstreamSingle + endpoint);

  if (options.verbose)
    options.log("futuresSubscribeSingle: Subscribed to " + endpoint);
  ws.reconnect = options.reconnect;
  ws.endpoint = endpoint;
  ws.isAlive = false;
  ws.onopen = handleFuturesSocketOpen.bind(ws, params.openCallback);
  // ws.onping = handleFuturesSocketHeartbeat
  ws.onerror = handleFuturesSocketError;
  ws.onclose = handleFuturesSocketClose.bind(ws, params.reconnect);
  ws.onmessage = (event) => {
    if (event.data.e === "ACCOUNT_UPDATE") {
      console.log(event.data);
    }
    callback(JSON.parse(event.data));
  };
  return ws;
};

// Futures internal functions
const futuresOrder = async (
  side,
  symbol,
  positionSide = "undefined",
  quantity,
  price = false,
  params = {},
) => {
  params.positionSide = positionSide;
  params.symbol = symbol;
  params.side = side;
  if (quantity !== undefined) params.quantity = quantity;
  // if in the binance futures setting Hedged mode is active, positionSide parameter is mandatory
  // if (typeof params.positionSide === 'undefined' && options.hedgeMode) {

  if (typeof params.positionSide === "undefined") {
    params.positionSide = side === "BUY" ? "LONG" : "SHORT";
  }
  // LIMIT STOP MARKET STOP_MARKET TAKE_PROFIT TAKE_PROFIT_MARKET
  // reduceOnly stopPrice

  if (price !== undefined) {
    params.price = price;
    if (typeof params.type === "undefined") params.type = "LIMIT";
  } else {
    if (typeof params.type === "undefined") params.type = "MARKET";
  }
  if (
    !params.timeInForce &&
    (params.type.includes("LIMIT") ||
      params.type === "STOP" ||
      params.type === "TAKE_PROFIT")
  ) {
    params.timeInForce = "GTC"; // Post only by default. Use GTC for limit orders.
  }
  return promiseRequest("v1/order", params, {
    base: fapi,
    type: "TRADE",
    method: "POST",
  });
};

export default {
  binanceSetAPIKey: async (params = {}) => {
    return setAPIOption(params);
  },

  futuresTestConnectivity: async (params = {}, callback) => {
    try {
      apiRequest(fapi + "v1/ping", {}, (r) => {
        // options.log("server time set: ", response.serverTime, info.timeOffset);
        var success = "success";
        if (callback) callback(success);
      });
    } catch (err) {
      if (callback) callback(err);
    }
    // return promiseRequest('v1/ping', params, { base: fapi, method: 'GET' })
  },

  /**
   * Futures WebSocket trades
   * @param {array/string} symbols - an array or string of symbols to query
   * @param {function} callback - callback function
   * @return {string} the websocket endpoint
   */
  futuresAggTradeStream: function (symbols, callback) {
    if (typeof symbols === "function") {
      callback = symbols;
      symbols = false;
    }
    let subscription;
    let cleanCallback = (data) =>
      callback(ConvertData.fAggTradeConvertData(data));
    let symbol = symbols;
    subscription = futuresSubscribeSingle(
      symbol.toLowerCase() + "@trade",
      cleanCallback,
    );
    // console.log(subscription)
    return subscription;
  },

  /**
   * Futures WebSocket trades
   * @param {array/string} symbols - an array or string of symbols to query
   * @param {function} callback - callback function
   * @return {string} the websocket endpoint
   */
  futuresMarkPriceStream: function (symbols, callback) {
    if (typeof symbols === "function") {
      callback = symbols;
      symbols = false;
    }
    let subscription;
    let cleanCallback = (data) => callback(data);
    let symbol = symbols;
    subscription = futuresSubscribeSingle(
      symbol.toLowerCase() + "@markPrice",
      cleanCallback,
    );

    return subscription;
  },

  /**
   * Futures WebSocket prevDay ticker
   * @param {symbol} symbol name or false. can also be a callback
   * @param {function} callback - callback function
   * @return {string} the websocket endpoint
   */
  futuresTickerStream: function fTickerStream(
    symbol = false,
    callback = console.log,
  ) {
    if (typeof symbol === "function") {
      callback = symbol;
      symbol = false;
    }
    let reconnect = () => {
      if (options.reconnect) fTickerStream(symbol, callback);
    };
    const endpoint = symbol ? `${symbol.toLowerCase()}@ticker` : "!ticker@arr";
    let subscription = futuresSubscribeSingle(
      endpoint,
      (data) => callback(ConvertData.fTickerConvertData(data)),
      { reconnect },
    );
    return subscription;
  },

  /**
   * Futures WebSocket
   * @param {symbol} symbol name or false. can also be a callback
   * @param {function} callback - callback function
   * @return {string} the websocket endpoint
   */
  futuresCandleStream: function fCandleStream(
    symbol = "btcusdt",
    interval,
    callback = console.log,
  ) {
    if (typeof symbol === "function") {
      callback = symbol;
      symbol = false;
    }

    let reconnect = () => {
      if (options.reconnect) fCandleStream(symbol, callback);
    };
    const endpoint = symbol
      ? `${symbol.toLowerCase()}@kline_${interval}`
      : `btcusdt@kline_${interval}`;
    let subscription = futuresSubscribeSingle(
      endpoint,
      (data) => callback(ConvertData.fKlineConvertData(data)),
      { reconnect },
    );
    return subscription.endpoint;
  },

  /**
   * Futures WebSocket prevDay ticker
   * @param {symbol} symbol name or false. can also be a callback
   * @param {function} callback - callback function
   * @return {string} the websocket endpoint
   */
  futuresUserDataStream: function fUserDataStream(
    listenKey = false,
    callback = console.log,
  ) {
    if (typeof listenKey === "function") {
      callback = listenKey;
      listenKey = false;
    }
    let reconnect = () => {
      if (options.reconnect) fUserDataStream(listenKey, callback);
    };
    const endpoint = listenKey;
    let subscription = futuresSubscribeSingle(
      endpoint,
      (data) => callback(data),
      { reconnect },
    );
    return subscription;
  },

  /**
   * Gets the candles information for a given symbol
   * intervals: 1m,3m,5m,15m,30m,1h,2h,4h,6h,8h,12h,1d,3d,1w,1M
   * @param {string} symbol - the symbol
   * @param {function} interval - the callback function
   * @param {function} callback - the callback function
   * @param {object} options - additional options
   * @return {promise or undefined} - omitting the callback returns a promise
   */
  futuresCandlesticks: function (
    symbol,
    interval = "1m",
    startTime,
    endTime,
    callback = false,
    options = { limit: 500 },
  ) {
    let params = Object.assign({
      symbol: symbol,
      interval: interval,
      startTime: startTime,
      endTime: endTime,
    });
    if (!callback) {
      return new Promise((resolve, reject) => {
        callback = (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        };
        publicRequest(fapi + "v1/klines", params, function (error, data) {
          return callback.call(this, error, data, symbol);
        });
      });
    } else {
      publicRequest(fapi + "v1/klines", params, function (error, data) {
        return callback.call(this, error, data, symbol);
      });
    }
  },

  futuresGetDataStream: async (params = {}) => {
    // A User Data Stream listenKey is valid for 60 minutes after creation. setInterval
    return promiseRequest("v1/listenKey", params, {
      base: fapi,
      type: "SIGNED",
      method: "POST",
    });
  },

  futuresKeepDataStream: async (params = {}) => {
    return promiseRequest("v1/listenKey", params, {
      base: fapi,
      type: "SIGNED",
      method: "PUT",
    });
  },

  futuresCloseDataStream: async (params = {}) => {
    return promiseRequest("v1/listenKey", params, {
      base: fapi,
      type: "SIGNED",
      method: "DELETE",
    });
  },

  futuresGetAccount: async (params = {}, callback = console.log) => {
    if (typeof params === "function") {
      callback = params;
      params = {};
    }
    promiseRequest("v2/account", params, { base: fapi, type: "SIGNED" }).then(
      (r) => {
        return callback(r);
      },
    );
  },

  futuresGetAllOpenOrders: async (params = {}, callback = console.log) => {
    if (typeof params === "function") {
      callback = params;
      params = {};
    }
    promiseRequest("v1/openOrders", params, {
      base: fapi,
      type: "SIGNED",
    }).then((r) => {
      return callback(r);
    });
  },

  futuresAllOrders: async (params = {}) => {
    return promiseRequest("v1/allOrders", params, {
      base: fapi,
      type: "SIGNED",
    });
  },

  futuresPositionInformation: async (params = {}) => {
    return promiseRequest("v1/positionRisk", params, {
      base: fapi,
      type: "SIGNED",
    });
  },

  futuresChangeLeverage: async (params = {}) => {
    return promiseRequest("v1/leverage", params, {
      base: fapi,
      method: "POST",
      type: "SIGNED",
    });
  },

  futuresOrderCancel: async (symbol = false, orderId = false, params = {}) => {
    if (!symbol || !orderId) throw Error("no parameters");
    params.symbol = symbol;
    params.orderId = orderId;
    return promiseRequest("v1/order", params, {
      base: fapi,
      type: "TRADE",
      method: "DELETE",
    });
  },

  futuresBuy: async (symbol, positionSide, quantity, price, params = {}) => {
    console.log("BUY ", quantity, symbol, "at", price);
    return futuresOrder("BUY", symbol, positionSide, quantity, price, params);
  },

  futuresSell: async (symbol, positionSide, quantity, price, params = {}) => {
    console.log("SELL ", quantity, symbol, "at", price);

    return futuresOrder("SELL", symbol, positionSide, quantity, price, params);
  },
};
