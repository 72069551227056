<template>
  <div>
    <v-app-bar
      dense
      height="32"
      flat
      class="transparent"
      style="border-bottom: 1px solid var(--v-background-base) !important"
    >
      <span class="caption font-weight-bold mr-2">Time</span>
      <v-btn-toggle v-model="interval" color="primary" group mandatory dense>
        <div v-for="i in activeIntervals" :key="i">
          <v-btn
            @click="getChartData(i)"
            class="caption px-0"
            :ripple="false"
            plain
            x-small
            :value="intervalSet[i]"
          >
            {{ intervalSet[i] }}
          </v-btn>
        </div>
      </v-btn-toggle>

      <v-menu :close-on-content-click="false" color="primary" bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" x-small icon>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-card rounded max-width="300" class="surface">
          <v-card-title class="caption">
            Add Interval

            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="px-2">
            <v-item-group v-model="activeIntervals" mandatory multiple>
              <v-container>
                <v-row>
                  <v-col
                    v-for="interval in intervalSet"
                    :key="interval"
                    cols="3"
                    xs="4"
                    class="text-center pa-1"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <v-btn
                        width="50"
                        :class="active ? 'primary' : ''"
                        depressed
                        small
                        @click="toggle"
                      >
                        {{ interval }}
                      </v-btn>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>
    <trading-vue
      :ref="id"
      :name="'qwe'"
      :width="width"
      :height="height - 32"
      :data="dc"
      :extensions="ext"
      :overlays="overlays"
      :toolbar="$vuetify.breakpoint.smAndUp"
      :color-back="'#00000000'"
      :chart-config="
        $vuetify.breakpoint.mdAndUp ? chartConfigLarge : chartConfigSmall
      "
      :indexBase="true"
      :color-grid="colors.grid"
      :color-text="colors.text"
      :color-cross="colors.cross"
      :color-candle-dw="colors.bear"
      :color-wick-dw="colors.bear"
      :color-candle-up="colors.bull"
      :color-wick-up="colors.bull"
      :timezone="9"
      :color-title="'var(--v-primary-base)'"
    >
      >
    </trading-vue>
    <v-alert
      v-if="width == 0 || height == 0"
      style="top: calc(50% - 28px); left: calc(50% - 150px); position: absolute"
      width="300px"
      type="warning"
      prominent
      text
      dense
    >
      새로고침이 필요합니다.
    </v-alert>
  </div>
</template>

<script>
import { TradingVue, DataCube } from "trading-vue-js";
// import Overlays from 'tvjs-overlays'
import PriceAverage from "@/components/utils/overlays/PriceAverage";
import LimitOrder from "@/components/utils/overlays/LimitOrder";
import XP from "tvjs-xp";

import Data from "@/data/trading/chart/data_v2.json";
import Utils from "../../../../../node_modules/trading-vue-js/src/stuff/utils";
import Const from "../../../../../node_modules/trading-vue-js/src/stuff/constants";
import binance from "@/APIs/binanceAPI";

export default {
  components: {
    TradingVue,
  },
  mounted() {
    this.getChartData(this.interval);
  },

  beforeDestroy() {
    this.wss.forEach((ws) => {
      ws.close();
    });
  },
  data() {
    return {
      dc: new DataCube(Data),
      ext: Object.values(XP),
      overlays: [PriceAverage, LimitOrder],

      intervalSet: [
        "1m",
        "3m",
        "15m",
        "30m",
        "1h",
        "2h",
        "4h",
        "6h",
        "8h",
        "12h",
        "1d",
        "3d",
        "1w",
        "1M",
      ],
      interval: "1m",

      chartConfigSmall: {
        DEFAULT_LEN: 20,
        MINIMUM_LEN: 25, // candles,
        MIN_ZOOM: 25, // candles
        MAX_ZOOM: 320, // candles,
      },
      chartConfigLarge: {
        DEFAULT_LEN: 150,
        MINIMUM_LEN: 25, // candles,
        MIN_ZOOM: 25, // candles
        MAX_ZOOM: 320, // candles,
      },

      wss: [],

      width: 0,
      height: 0,
      id: "tvjs" + Math.floor(Math.random()),
    };
  },
  computed: {
    recentTrade: {
      get() {
        return this.$store.getters[
          "trading/binance/futures/stream/RECENT_TRADE"
        ];
      },
    },
    activeIntervals: {
      get() {
        return this.$store.getters[
          "trading/preferance/system/chart/ACTIVE_INTERVAL_SORT"
        ];
      },
      set(val) {
        this.$store.dispatch(
          "trading/preferance/system/chart/SET_ACTIVE_INTERVAL",
          val,
        );
      },
    },
    colors() {
      return this.$vuetify.theme.dark
        ? {
            back: "#00000000",
            grid: "#2f2f2f",
            text: "#fff",
            bull: this.$vuetify.theme.themes.dark.bull,
            bear: this.$vuetify.theme.themes.dark.bear,
          }
        : {
            back: "#00000000",
            grid: "#eee",
            text: "#333",
            bull: this.$vuetify.theme.themes.light.bull,
            bear: this.$vuetify.theme.themes.light.bear,
          };
    },
  },
  methods: {
    async getChartData(n = "1m") {
      this.resetChart();
      var interval;
      typeof n === "number" ? (interval = this.intervalSet[n]) : (interval = n);

      const now = Utils.now();
      var timeGap = this.getTimeGapByInterval(interval);
      this.loadCandleStick([now - timeGap, now], interval);
      this.loadOverlay();
      await this.updateWithTradeStream();
    },
    async loadOverlay() {
      var position =
        this.$store.getters[
          "trading/binance/futures/user/CURRENT_POSITION_INFORMATION"
        ];
      var positions = [];
      position.forEach((e) => {
        var data = [
          e.updateTime,
          parseFloat(e.entryPrice),
          parseFloat(e.positionAmt),
        ];
        positions.push(data);
      });
    },
    async loadCandleStick(range, interval) {
      // check if new interval called
      var set = this.interval !== interval;

      const [t1, t2] = range;
      const symbol = "BTCUSDT";
      await binance.futuresCandlesticks(symbol, interval, t1, t2, (r) => {
        var data = this.parse_binance(r);
        if (set) {
          this.resetChart();
          this.setChartData("chart.data", data);
        } else {
          this.mergeChartData("chart.data", data);
        }
        this.dc.onrange((t) => this.loadCandleStick(t, interval));
      });
    },

    setChartData(target, data) {
      this.dc.set(target, data);
    },
    mergeChartData(target, data) {
      this.dc.merge(target, data);
    },

    // Parse a specific exchange format
    parse_binance(data) {
      if (!Array.isArray(data)) return [];
      return data.map((x) => {
        for (var i = 0; i < x.length; i++) {
          x[i] = parseFloat(x[i]);
        }
        return x.slice(0, 6);
      });
    },

    getTimeGapByInterval(interval) {
      if (interval === "1m") {
        return Const.HOUR4;
      } else if (interval === "3m") {
        return Const.DAY;
      } else if (interval === "15m") {
        return Const.DAY * 3;
      } else if (interval === "30m") {
        return Const.WEEK;
      } else if (interval === "1h") {
        return Const.WEEK;
      } else if (interval === "2h") {
        return Const.WEEK;
      } else if (interval === "4h") {
        return Const.MONTH;
      } else if (interval === "6h") {
        return Const.MONTH;
      } else if (interval === "8h") {
        return Const.MONTH;
      } else if (interval === "12h") {
        return Const.MONTH * 3;
      } else {
        return Const.YEAR;
      }
    },
    async resetChart() {
      await this.$refs[this.id].resetChart();
    },
    updateChartPanel(data) {
      this.dc.update({
        price: parseFloat(data.price),
        volume: parseFloat(data.amount),
      });
      // }
    },

    updateWithCandleStream(interval) {
      binance.futuresCandleStream("BTCUSDT", interval, this.updateChartPanel);
    },
    updateWithTradeStream() {
      var ws = binance.futuresAggTradeStream("BTCUSDT", this.updateChartPanel);
      this.wss.push(ws);
    },

    sortArray(arr) {
      arr.sort((a, b) => {
        if (a > b) return 1;
        if (a === b) return 0;
        if (a < b) return -1;
      });
    },
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
  },
};
</script>
