var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"secondary",attrs:{"color":"background","dark":_vm.$vuetify.theme.dark,"flat":"","app":""}},[_c('v-app-bar-title',{staticClass:"mr-5"},[_c('v-img',{staticStyle:{"cursor":"pointer"},style:(_vm.$vuetify.theme.dark ? '' : 'filter: brightness(1) invert(1);'),attrs:{"contain":"","width":"111","src":require('@/assets/logos/woozLabs_white.svg')},on:{"click":function($event){return _vm.routerPush('Home')}}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-title',[_c('span',{staticClass:"font-weight-bold subtitle-1"},[_vm._v(" "+_vm._s(_vm.$moment().format("MM월 DD일 (ddd)"))+" - "+_vm._s(_vm.currentTime)+" ")])]):_vm._e(),_c('v-spacer'),_c('v-alert',{staticClass:"ma-0",attrs:{"type":"error","dense":""}},[_vm._v(" Refactoring is on process. This page will not work. ")]),(
        _vm.$vuetify.breakpoint.mdAndUp && _vm.$store.getters['auth/user/GET_USER']
      )?_c('v-menu',{attrs:{"offset-y":"","left":"","transition":"scale-transition","origin":"right top","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"onSurface"}},[_vm._v("mdi-qrcode")])],1)]}}],null,false,1197121924)},[_c('APIKeyConnectMenu')],1):_vm._e(),(
        !_vm.$store.getters['auth/user/GET_USER'] && _vm.$vuetify.breakpoint.mdAndUp
      )?_c('v-btn',{staticClass:"body-2 font-weight-bold mx-2 primary",attrs:{"text":""},on:{"click":function($event){return _vm.routerPush('Login')}}},[_vm._v(" Log In ")]):_vm._e(),_c('more-menu',{scopedSlots:_vm._u([{key:"moreMenuTrigger",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"background mx-2",attrs:{"width":"40","height":"40","icon":"","depressed":"","ripple":false}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"36","color":"surface"}},[_c('v-img',{attrs:{"src":_vm.$store.getters['auth/user/GET_USER'].photoURL}})],1)],1)]}}])}),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.toggleDrawer}},[_c('v-img',{style:(_vm.$vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''),attrs:{"height":"24","contain":"","src":require('@/assets/icons/hamburger.svg')}})],1):_vm._e()],1),_c('navigation-drawer',{attrs:{"drawer":_vm.drawer},on:{"toggleDrawer":_vm.toggleDrawer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }