<template>
  <v-data-table
    :headers="headers"
    :items="positionInformation"
    :items-per-page="-1"
    hide-default-footer
    no-data-text=""
    no-results-text=""
    disable-sort
    dense
  >
    <template v-slot:[`item.symbol`]="{ item }">
      <div>
        <span
          :class="getPositionColor(item.positionSide) + '--text'"
          class="font-weight-bold"
          >{{ item.symbol }}</span
        >
        <v-chip
          class="ma-2 pa-2 font-weight-bold"
          :color="getPositionColor(item.positionSide)"
          label
          small
          dark
        >
          x{{ item.leverage }}
        </v-chip>
      </div>
    </template>
    <template v-slot:[`item.positionAmt`]="{ item }">
      <div>
        <span
          :class="getPositionColor(item.positionSide) + '--text'"
          class="font-weight-bold"
          >{{ item.positionAmt }} {{ item.symbol }}</span
        >
      </div>
    </template>

    <template v-slot:[`item.entryPrice`]="{ item }">
      <div>
        <span class="font-weight-regular">{{
          numberWithCommas(item.entryPrice)
        }}</span>
      </div>
    </template>

    <template v-slot:[`item.markPrice`]="{}">
      <div>
        <span class="font-weight-regular">{{
          numberWithCommas(getMarkPrice)
        }}</span>
      </div>
    </template>
    <template v-slot:[`item.liquidationPrice`]="{ item }">
      <div>
        <span class="font-weight-regular">{{
          numberWithCommas(item.liquidationPrice)
        }}</span>
      </div>
    </template>
    <template v-slot:[`item.unRealizedProfit`]="{ item }">
      <div>
        <!-- BULL -->
        <span
          v-if="(getMarkPrice - item.entryPrice) * item.positionAmt > 0"
          class="font-weight-bold bull--text"
        >
          {{ getPNL((getMarkPrice - item.entryPrice) * item.positionAmt) }} USDT
          ({{
            (
              (((getMarkPrice - item.entryPrice) * item.positionAmt) /
                (item.entryPrice * Math.abs(item.positionAmt))) *
              100 *
              item.leverage
            ).toFixed(2)
          }}%)
        </span>
        <!-- BEAR -->
        <span
          v-else-if="(getMarkPrice - item.entryPrice) * item.positionAmt < 0"
          class="font-weight-bold bear--text"
        >
          {{ getPNL((getMarkPrice - item.entryPrice) * item.positionAmt) }} USDT
          ({{
            (
              (((getMarkPrice - item.entryPrice) * item.positionAmt) /
                (item.entryPrice * Math.abs(item.positionAmt))) *
              100 *
              item.leverage
            ).toFixed(2)
          }}%)
        </span>
        <span v-else class="font-weight-bold">
          {{ getPNL((getMarkPrice - item.entryPrice) * item.positionAmt) }}
          ({{
            (
              (((getMarkPrice - item.entryPrice) * item.positionAmt) /
                (item.entryPrice * Math.abs(item.positionAmt))) *
              100 *
              item.leverage
            ).toFixed(2)
          }}%)
        </span>
      </div>
    </template>

    <template v-slot:no-data> </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template> -->
  </v-data-table>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Symbol",
        class: "font-weight-regular",
        align: "start",
        value: "symbol",
      },
      // { text: 'Margin Type', value: 'marginType', sortable: false },
      // { text: 'Side', value: 'positionSide', sortable: false },
      {
        text: "Size",
        class: "font-weight-regular",
        value: "positionAmt",
      },
      {
        text: "Entry Price",
        class: "font-weight-regular",
        value: "entryPrice",
      },
      {
        text: "Mark Price",
        class: "font-weight-regular",
        value: "markPrice",
      },
      {
        text: "Liq.Price",
        class: "font-weight-regular",
        value: "liquidationPrice",
      },
      // {
      //   text: 'Margin Ratio',
      //   class: 'font-weight-regular',
      //   value: 'margin'
      // },
      {
        text: "PNL(ROE %)",
        class: "font-weight-regular",
        value: "unRealizedProfit",
      },
      {
        text: "Close All Positions",
        class: "font-weight-regular",
        value: "closePosition",
      },
      {
        text: "TP / SL",
        class: "font-weight-regular",
        value: "tpsl",
      },
    ],
    position: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    getPNLColor() {
      return (v) => {
        if (v > 0) return "bull";
        else if (v < 0) return "bear";
        else return "secondary";
      };
    },
    getMarkPrice() {
      if (this.$store.getters["trading/binance/futures/stream/RECENT_TRADE"]) {
        var price =
          this.$store.getters["trading/binance/futures/stream/RECENT_TRADE"]
            .price;
      }
      if (price) {
        return price;
      } else {
        return 0;
      }
    },
    positionInformation() {
      var data =
        this.$store.getters[
          "trading/binance/futures/user/CURRENT_POSITION_INFORMATION"
        ];
      data[0].positionAmt = data[0].positionAmt * 1000;
      return data;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {},
  methods: {
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },

    getPositionColor(p) {
      if (p === "SHORT") {
        return "bear";
      } else if (p === "LONG") {
        return "bull";
      } else {
        return "secondary";
      }
    },
    numberWithCommas(x) {
      if (typeof x === "string") {
        x = parseFloat(x).toFixed(2);
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getPNL(x) {
      if (typeof x === "string") x = parseFloat(x);
      x = x.toFixed(2);
      x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return x;
    },
    getPNLTextColor(x) {
      if (x > 0) return "bull";
      else if (x < 0) return "bear";
    },
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },
  },
};
</script>
