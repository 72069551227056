<template>
  <div>
    <!-- <v-card-title class="pa-4 subtitle-2 font-weight-bold">
    <div class="vue-draggable-handle"></div>
    Order Form
    <v-spacer></v-spacer>

  </v-card-title> -->

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container grid-list-xs>
          <v-row>
            <v-col align-self="center" class="pa-0 py-2" cols="2">
              <div class="font-weight-bold body-2">Mode.</div>
            </v-col>
            <v-col style="place-self: center" class="py-2 px-0" cols="4">
              <v-btn
                class="font-weight-bold secondary surface--text body-2"
                small
                depressed
                >Isolated</v-btn
              >
            </v-col>

            <v-col align-self="center" class="pa-0 py-2" cols="2">
              <div class="font-weight-bold body-2">Lev.</div>
            </v-col>
            <v-col class="py-2 px-0" cols="4">
              <v-text-field
                class="pa-0"
                v-model="leverage"
                required
                :rules="[(v) => v > 0 && v <= 125]"
                type="number"
                hide-details
                @change="changeLeverage"
                outlined
                dense
                flat
              >
              </v-text-field>
            </v-col>

            <v-col align-self="center" class="pa-0 py-2" cols="2">
              <div class="font-weight-bold body-2">Avbl.</div>
              <!-- <span class="caption"> (USDT)</span> -->
            </v-col>
            <v-col class="pa-0 py-2" cols="10">
              <div class="text-end title">
                {{ parseFloat(availableBalance).toFixed(3) }}
                <span class="caption"> USDT </span>
              </div>
            </v-col>

            <!-- PRICE -->
            <v-col class="pa-0 py-2" cols="2">
              <div class="font-weight-bold body-2">Price</div>
              <span class="caption"> (USDT)</span>
            </v-col>
            <v-col class="pa-0 py-2" cols="10">
              <v-text-field
                :rules="[(v) => v > 0]"
                type="number"
                v-model="price"
                label=""
                required
                hide-details
                outlined
                dense
                flat
              >
                <template v-slot:append>
                  <v-btn
                    @click="price = lastTradePrice"
                    :disabled="autoOffer"
                    color="primary"
                    text
                    >Last</v-btn
                  >
                </template>
              </v-text-field>
            </v-col>

            <!-- SIZE -->
            <v-col class="pa-0 py-2" cols="2">
              <div class="font-weight-bold body-2">Size</div>
              <span class="caption"> (BTC)</span>
            </v-col>
            <v-col class="pa-0 py-2" cols="10">
              <v-text-field
                v-model="size"
                :rules="[(v) => v > 0]"
                type="number"
                required
                hide-details
                outlined
                dense
                flat
              >
              </v-text-field>
              <v-item-group>
                <v-container>
                  <v-row v-if="!sizeSliderActive">
                    <v-col
                      v-for="(item, i) in sizePercentageSet"
                      :key="item"
                      cols="3"
                      class="pr-1 py-2 pl-0 pb-0"
                    >
                      <v-item v-slot="{ active }">
                        <v-btn
                          small
                          outlined
                          :text="!active"
                          width="100%"
                          :color="active ? 'primary' : ''"
                          @click="sizePercent = sizePercentageSet[i]"
                        >
                          {{ item }}%
                        </v-btn>
                      </v-item>
                    </v-col>
                    <v-col cols="3" class="pr-1 py-2 pl-0 pb-0">
                      <v-item v-slot="{ active }">
                        <v-btn
                          small
                          outlined
                          :text="!active"
                          width="100%"
                          :color="active ? 'primary' : ''"
                          @click="sizeSliderActive = !sizeSliderActive"
                        >
                          직접입력
                        </v-btn>
                      </v-item>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <v-slider
                        style="align-items: center"
                        v-model="sizePercent"
                        step="1"
                        hide-details
                        dense
                        :max="100"
                        thumb-label="always"
                        thumb-size="42"
                        tick-size="8"
                      >
                        <template v-slot:thumb-label="{ value }">
                          <span class="font-weight-bold caption"
                            >{{ value }}%</span
                          >
                        </template>

                        <template v-slot:append>
                          <v-btn
                            color="primary"
                            depressed
                            small
                            @click="sizeSliderActive = !sizeSliderActive"
                            >확인</v-btn
                          >
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-col>

            <!-- TOTAL -->
            <v-col class="pa-0 py-2" cols="2">
              <div class="font-weight-bold body-2">Total</div>
              <span class="caption"> (USDT)</span>
            </v-col>
            <v-col class="pa-0 py-2" cols="10">
              <v-text-field
                :value="totalAmount"
                label=""
                required
                hide-details
                readonly
                outlined
                dense
                flat
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="my-4"></v-divider>
          </v-row>

          <v-row>
            <v-col cols="12" class="pa-0 py-2">
              <v-switch
                v-model="autoOffer"
                inset
                class="ma-0"
                :ripple="false"
                hide-details
              >
                <template v-slot:label>
                  <span class="body-2">Auto Offer</span>
                </template>
              </v-switch>

              <v-container v-if="autoOffer">
                <v-row>
                  <!-- OFFSET -->
                  <v-col class="pa-0 py-2" cols="2">
                    <div class="font-weight-bold body-2">Offset</div>
                    <span class="caption"> (%)</span>
                  </v-col>
                  <v-col class="pa-0 py-2" cols="10">
                    <v-text-field
                      v-model="autoOfferOffset"
                      :rules="[(v) => v > 0]"
                      type="number"
                      label=""
                      required
                      persistent-hint
                      hint="''"
                      outlined
                      dense
                      flat
                    >
                      <template v-slot:message>
                        <span
                          >Your offer price will be
                          <div class="mt-1">
                            Long :
                            {{
                              numberWithCommas(
                                lastTradePrice * (1 + autoOfferOffset / 100),
                              )
                            }}
                            USDT
                          </div>
                          <div>
                            Short :
                            {{
                              numberWithCommas(
                                lastTradePrice * (1 - autoOfferOffset / 100),
                              )
                            }}
                            USDT
                          </div>
                        </span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" class="pa-0 py-2">
              <v-switch
                v-model="tpsl"
                inset
                class="ma-0"
                :ripple="false"
                hide-details
              >
                <template v-slot:label>
                  <span class="body-2">Take Profit / Stop Loss</span>
                </template>
              </v-switch>

              <v-container v-if="tpsl">
                <v-row>
                  <!-- TAKE PROFIT -->
                  <v-col class="pa-0 py-2" cols="2">
                    <div class="font-weight-bold body-2">Take Profit</div>
                    <span class="caption"> (BTC)</span>
                  </v-col>
                  <v-col class="pa-0 py-2" cols="10">
                    <v-text-field
                      v-model="tp"
                      label=""
                      :rules="[(v) => v > 0]"
                      type="number"
                      required
                      persistent-hint
                      hint="Your profit will be 0.00 USDT"
                      outlined
                      dense
                      flat
                    >
                      <template v-slot:message>
                        <span
                          >Your profit will be
                          <div class="mt-1">
                            Long :
                            {{
                              numberWithCommas(
                                (tp - orderPrice) * orderSize > 0
                                  ? (tp - orderPrice) * orderSize
                                  : 0,
                              )
                            }}
                            USDT
                          </div>
                          <div>
                            Short :
                            {{
                              numberWithCommas(
                                (orderPrice - tp) * orderSize > 0
                                  ? (orderPrice - tp) * orderSize
                                  : 0,
                              )
                            }}
                            USDT
                          </div>
                        </span>
                      </template>
                      <template v-slot:append>
                        <v-btn @click="tp = lastTradePrice" color="primary" text
                          >Last</v-btn
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- STOP LOSS -->
                  <v-col class="pa-0 py-2" cols="2">
                    <div class="font-weight-bold body-2">Stop Loss</div>
                    <span class="caption"> (BTC)</span>
                  </v-col>
                  <v-col class="pa-0 py-2" cols="10">
                    <v-text-field
                      v-model="sl"
                      :rules="[(v) => v > 0]"
                      type="number"
                      required
                      persistent-hint
                      hint="Your loss will be 0.00 USDT"
                      outlined
                      dense
                      flat
                    >
                      <template v-slot:message>
                        <span
                          >Your loss will be
                          <div class="mt-1">
                            Long :
                            {{
                              numberWithCommas(
                                (orderPrice - sl) * orderSize > 0
                                  ? (orderPrice - sl) * orderSize
                                  : 0,
                              )
                            }}
                            USDT
                          </div>
                          <div>
                            Short :
                            {{
                              numberWithCommas(
                                (sl - orderPrice > 0) * orderSize
                                  ? (sl - orderPrice) * orderSize
                                  : 0,
                              )
                            }}
                            USDT
                          </div>
                        </span>
                      </template>

                      <template v-slot:append>
                        <v-btn @click="sl = lastTradePrice" color="primary" text
                          >Last</v-btn
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

          <v-row>
            <v-divider class="my-4"></v-divider>
          </v-row>
          <v-row
            v-if="
              !$store.getters['auth/user/GET_USER'] ||
              !$store.getters['trading/preferance/system/api/API_LOADED']
            "
          >
            <v-btn
              @click="$router.push({ name: 'Login' })"
              text
              :ripple="false"
              class="body-2 mx-auto"
              color="primary"
              depressed
              >Log In or Register Now</v-btn
            >
          </v-row>
          <v-row v-else>
            <v-col class="px-0 pr-1" cols="6">
              <v-btn
                @click="order('BUY', 'LONG')"
                color="bull"
                dark
                class="body-2 font-weight-bold my-2"
                block
                large
                depressed
              >
                Open Long
              </v-btn>
              <v-btn
                color="bull"
                class="body-2 font-weight-bold my-2"
                block
                large
                outlined
                @click="order('SELL', 'LONG')"
              >
                Close Long
              </v-btn>

              <!-- <div class="caption my-2 text-start">
          <div>
            Cost <span>0.00 USDT</span>
          </div>
          <div>
            Max <span>0.00 USDT</span>
          </div>
        </div> -->
            </v-col>
            <v-col class="px-0 pl-1" cols="6">
              <v-btn
                color="bear"
                dark
                @click="order('SELL', 'SHORT')"
                class="body-2 font-weight-bold my-2"
                block
                large
                depressed
              >
                Open Short
              </v-btn>

              <v-btn
                color="bear"
                class="body-2 font-weight-bold my-2"
                block
                large
                outlined
                @click="order('BUY', 'SHORT')"
              >
                Close Short
              </v-btn>

              <!-- <div class="caption my-2 text-end">
          <div>
            Cost <span>0.00 USDT</span>
          </div>
          <div>
            Max <span>0.00 USDT</span>
          </div>
        </div> -->
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import binance from "@/APIs/binanceAPI";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
  },
  mounted() {},
  computed: {
    lastTradePrice: {
      get() {
        return this.$store.getters[
          "trading/binance/futures/stream/RECENT_TRADE"
        ].price;
      },
    },

    availableBalance: {
      get() {
        // return 10000
        if (
          this.$store.getters[
            "trading/binance/futures/user/AVAILABLE_BALANCE"
          ] == null
        )
          return 0;
        return this.$store.getters[
          "trading/binance/futures/user/AVAILABLE_BALANCE"
        ];
      },
    },
    leverage: {
      get() {
        return this.orderLeverage;
      },
      set(val) {
        this.orderLeverage = val;
      },
    },
    price: {
      get() {
        if (this.autoOffer) {
          return this.lastTradePrice;
        }
        return this.orderPrice;
      },
      set(val) {
        if (this.autoOffer) this.orderPrice = this.lastTradePrice;
        this.orderPrice = val;
      },
    },

    size: {
      get() {
        return this.price ? this.orderSize : 0;
      },
      set(val) {
        this.orderSize = val;
        this.totalAmount = this.price * (val / this.leverage);
      },
    },
    sizePercent: {
      get() {
        return this.orderSizePercent;
      },
      set(val) {
        if (this.price > 0) {
          var n = parseFloat(
            (((this.availableBalance / 100) * val) / this.price) *
              this.leverage,
          );
          this.size = Math.floor(n * 1000) / 1000;
        }
      },
    },
    totalAmount: {
      get() {
        return (this.price * (this.size / this.leverage)).toFixed(3);
      },
      set(val) {
        this.orderTotal = val;
      },
    },
  },

  beforeDestroy() {},
  data() {
    return {
      valid: false,
      orderLeverage: 10,
      sizePercentageSet: [0, 25, 33, 50, 67, 75, 100],
      sizeSliderActive: false,

      orderSizePercent: 0,
      orderTotal: 0,
      orderPrice: 0,
      orderSize: 0,

      autoOffer: false,
      autoOfferOffset: 0,
      tpsl: false,
      tp: 0,
      sl: 0,
    };
  },
  methods: {
    order(type = "BUY", positionSide = "LONG") {
      if (type === "BUY") {
        if (positionSide === "LONG") {
          // OPEN LONG
          binance
            .futuresBuy("BTCUSDT", "LONG", this.orderSize, this.price)
            .then((r) => {
              this.$toasted.global.notice("Order Submitted");
              if (r.code) {
                this.$toasted.global.error(r.msg);
              } else {
                console.log(r);
                this.$store.dispatch(
                  "trading/binance/futures/user/PUSH_OPEN_ORDERS",
                  r,
                );
                this.$toasted.global.success("Order Created");
              }
            });
        } else {
          // CLOSE SHORT
          binance
            .futuresBuy("BTCUSDT", "SHORT", this.orderSize, this.price)
            .then((r) => {
              this.$toasted.global.notice("Order Submitted");
              if (r.code) {
                this.$toasted.global.error(r.msg);
              } else {
                console.log(r);
                this.$store.dispatch(
                  "trading/binance/futures/user/PUSH_OPEN_ORDERS",
                  r,
                );
                this.$toasted.global.success("Order Created");
              }
            });
        }
      } else {
        if (positionSide === "LONG") {
          // CLOSE LONG
          binance
            .futuresSell("BTCUSDT", "LONG", this.orderSize, this.price)
            .then((r) => {
              this.$toasted.global.notice("Order Submitted");
              if (r.code) {
                this.$toasted.global.error(r.msg);
              } else {
                console.log(r);
                this.$store.dispatch(
                  "trading/binance/futures/user/PUSH_OPEN_ORDERS",
                  r,
                );
                this.$toasted.global.success("Order Created");
              }
            });
        } else {
          // OPEN SHORT
          binance
            .futuresSell("BTCUSDT", "SHORT", this.orderSize, this.price)
            .then((r) => {
              this.$toasted.global.notice("Order Submitted");
              if (r.code) {
                this.$toasted.global.error(r.msg);
              } else {
                console.log(r);
                this.$store.dispatch(
                  "trading/binance/futures/user/PUSH_OPEN_ORDERS",
                  r,
                );
                this.$toasted.global.success("Order Created");
              }
            });
        }
      }
    },
    changeLeverage() {
      binance
        .futuresChangeLeverage({
          symbol: "BTCUSDT",
          leverage: parseInt(this.orderLeverage),
        })
        .then((r) => {
          this.$toasted.global.success("Leverage changed to X" + r.leverage);
          console.log(r);
        });
    },
    handleResize(w, h) {
      this.width = w;
      this.height = h;
    },

    getMakerTakerColor(m) {
      return m ? "bear" : "bull";
    },
    numberWithCommas(x) {
      if (typeof x === "string") {
        x = parseFloat(x).toFixed(2);
      }

      x = parseFloat(x).toFixed(2);
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
