var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.positionInformation,"items-per-page":-1,"hide-default-footer":"","no-data-text":"","no-results-text":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-bold",class:_vm.getPositionColor(item.positionSide) + '--text'},[_vm._v(_vm._s(item.symbol))]),_c('v-chip',{staticClass:"ma-2 pa-2 font-weight-bold",attrs:{"color":_vm.getPositionColor(item.positionSide),"label":"","small":"","dark":""}},[_vm._v(" x"+_vm._s(item.leverage)+" ")])],1)]}},{key:"item.positionAmt",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-bold",class:_vm.getPositionColor(item.positionSide) + '--text'},[_vm._v(_vm._s(item.positionAmt)+" "+_vm._s(item.symbol))])])]}},{key:"item.entryPrice",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.numberWithCommas(item.entryPrice)))])])]}},{key:"item.markPrice",fn:function(ref){return [_c('div',[_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.numberWithCommas(_vm.getMarkPrice)))])])]}},{key:"item.liquidationPrice",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.numberWithCommas(item.liquidationPrice)))])])]}},{key:"item.unRealizedProfit",fn:function(ref){
var item = ref.item;
return [_c('div',[((_vm.getMarkPrice - item.entryPrice) * item.positionAmt > 0)?_c('span',{staticClass:"font-weight-bold bull--text"},[_vm._v(" "+_vm._s(_vm.getPNL((_vm.getMarkPrice - item.entryPrice) * item.positionAmt))+" USDT ("+_vm._s(( (((_vm.getMarkPrice - item.entryPrice) * item.positionAmt) / (item.entryPrice * Math.abs(item.positionAmt))) * 100 * item.leverage ).toFixed(2))+"%) ")]):((_vm.getMarkPrice - item.entryPrice) * item.positionAmt < 0)?_c('span',{staticClass:"font-weight-bold bear--text"},[_vm._v(" "+_vm._s(_vm.getPNL((_vm.getMarkPrice - item.entryPrice) * item.positionAmt))+" USDT ("+_vm._s(( (((_vm.getMarkPrice - item.entryPrice) * item.positionAmt) / (item.entryPrice * Math.abs(item.positionAmt))) * 100 * item.leverage ).toFixed(2))+"%) ")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getPNL((_vm.getMarkPrice - item.entryPrice) * item.positionAmt))+" ("+_vm._s(( (((_vm.getMarkPrice - item.entryPrice) * item.positionAmt) / (item.entryPrice * Math.abs(item.positionAmt))) * 100 * item.leverage ).toFixed(2))+"%) ")])])]}},{key:"no-data",fn:function(){return undefined},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }